:root{
  --var-bg-color: #E1F4F5

}
.App {
  text-align: center;
  background-image: linear-gradient(121.51deg, var(--var-bg-color) 30.38%, var(--var-bg-color) 53.04%, #D6EBF0 71.29%);
}
.mantine-590fnk {
  top: -25% !important;
  left: 0;
}
